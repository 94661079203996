import { APIDCN } from '@api/base';
import type { TAction, TDispatch } from '@models/types';

import { PromoActionType } from './types';

import type { PromoAction, PromoModel } from './types';

const PromoDefault: PromoModel = {
  promoDetail: {
    createdBy: '',
    createdDate: '',
    description: '',
    hasCoupons: false,
    id: '',
    isActive: false,
    isExclusive: false,
    maxPersonalUsageCount: 0,
    maxUsageCount: 0,
    maxUsageOnOrder: 0,
    modifiedBy: '',
    modifiedDate: '',
    name: '',
    outerId: '',
    priority: 0,
    startDate: '',
    store: '',
    storeIds: [],
    type: ''
  },
  promoGrid: []
};

const PromoReducer = (state: PromoModel = PromoDefault, action: PromoAction): PromoModel => {
  switch (action.type) {
    case PromoActionType.GetPromoDetail:
      return { ...state, promoDetail: action.data };
    case PromoActionType.PostPromoGrid:
      return { ...state, promoGrid: action.data };

    default:
      return { ...state };
  }
};

const PromoCommand = {
  getPromoDetail: (params: string): TAction<PromoAction, void> => {
    return (dispatch: TDispatch<PromoAction>) => {
      return APIDCN.get(`/promotion/1/agent/promotion/get-promotion-by-id?id=${params}`)
        .then((response) => {
          dispatch({
            data: response.data,
            type: PromoActionType.GetPromoDetail
          });
        });
    };
  },
  postPromoGrid: (): TAction<PromoAction, void> => {
    return (dispatch: TDispatch<PromoAction>) => {
      return APIDCN.post(
        '/promotion/1/agent/promotion/search-promotion',
        {
          onlyActive: false,
          searchPhrase: '',
          skip: 0,
          sort: '',
          take: 10
        }
      )
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: PromoActionType.PostPromoGrid
          });
        });
    };
  }
};

export { PromoCommand, PromoReducer, PromoDefault };
